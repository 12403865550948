import axios from 'axios'
import { ProductUM, ProductOptionUM, GiftUM, ConditionalGiftUM } from '@/model/ui/product-ui-model'

export default {
  // 取得產品詳細資訊
  GetProduct: async function (id) {
    const url = `${process.env.VUE_APP_API}/api/product/details?id=${id}`
    const rsp = await axios.get(url)
    if (rsp == null || rsp.data == null || rsp.data.rtnCode !== 0) {
      return null
    }

    if (rsp.data.info == null) {
      return null
    }

    const data = rsp.data.info
    const d = new ProductUM()
    d.Id = data.productId
    d.Name = data.name
    d.ShortDescription = data.shortDescription
    d.Price = data.price
    d.MSRP = data.oldPrice
    d.Specification = data.specification
    d.Description = data.description
    d.Tags = data.tags
    d.Image = data.productImage
    d.SaleStatus = data.saleStatus
    d.ForSale = data.forSale
    d.Type = data.type
    d.Slogan = data.slogan
    d.ProductOtherImg = data.productOtherImg

    if (d.ProductOtherImg) {
      d.ProductOtherImg.unshift({
        imageUrl: '',
        imgFilePath: d.Image
      })
    }
    /* 商品圖片組 */
    // if (data.images != null) {
    //   d.Images = data.images.map((pdi) => {
    //     const p = new ProductImageUM()
    //     p.ImageUrl = pdi.image
    //     p.VideoUrl = pdi.targetUrl

    //     return p
    //   })
    // }
    if (data.options !== null) {
      d.Options = data.options.map((pdi) => {
        const p = new ProductOptionUM()
        p.Id = pdi.optionId
        p.Name = pdi.optionName
        p.Stock = pdi.stock
        p.RealStock = pdi.realStock
        p.DisplayStock = pdi.displayStock

        return p
      })
    }
    // * 商品贈品
    if (data.gift !== null) {
      d.Gift = new GiftUM()
      d.Gift.Id = data.gift.giftId
      d.Gift.Name = data.gift.giftName
    }

    // * 滿額贈
    if (data.conditionalGift) {
      d.ConditionalGift = data.conditionalGift.map((gift) => {
        const p = new ConditionalGiftUM()
        p.Id = gift.giftId
        p.Name = gift.giftName
        p.LowerLimit = gift.lowerLimit

        return p
      })
    }

    return d
  },
  // * 取得熱門商品資訊
  GetHotProduct: async function () {
    const url = `${process.env.VUE_APP_API}/api/widgets/hotproduct`
    const res = await axios.get(url)
    if (res.data.info) {
      const rtnData = res.data.info.map((item) => {
        const d = new ProductUM()
        d.Id = item.productId
        d.Name = item.name
        d.Price = item.price
        d.MSRP = item.oldPrice
        d.Image = item.productImage
        return d
      })
      return rtnData
    }
  },
  // *2022生日禮
  GetBirthProduct: async function () {
    const url = `${process.env.VUE_APP_API}/api/product/details?id=P220615000001`
    const rsp = await axios.get(url)
    if (rsp == null || rsp.data == null || rsp.data.rtnCode !== 0) {
      return null
    }

    if (rsp.data.info == null) {
      return null
    }

    const data = rsp.data.info

    const d = new ProductUM()
    d.Id = data.productId
    d.Name = data.name
    d.ShortDescription = data.shortDescription
    d.Price = data.price
    d.MSRP = data.oldPrice
    d.Specification = data.specification
    d.Description = data.description
    d.Tags = data.tags
    d.Image = data.productImage
    d.SaleStatus = data.saleStatus

    if (data.options !== null) {
      d.Options = data.options.map((pdi) => {
        const p = new ProductOptionUM()
        p.Id = pdi.optionId
        p.Name = pdi.optionName
        p.Stock = pdi.stock

        return p
      })
    }
    return d
  },
  // *2023生日禮
  GetBirthProduct_2023: async function () {
    const url = `${process.env.VUE_APP_API}/api/product/details?id=P230612000001`
    const rsp = await axios.get(url)
    if (rsp == null || rsp.data == null || rsp.data.rtnCode !== 0) {
      return null
    }

    if (rsp.data.info == null) {
      return null
    }

    const data = rsp.data.info

    const d = new ProductUM()
    d.Id = data.productId
    d.Name = data.name
    d.ShortDescription = data.shortDescription
    d.Price = data.price
    d.MSRP = data.oldPrice
    d.Specification = data.specification
    d.Description = data.description
    d.Tags = data.tags
    d.Image = data.productImage
    d.SaleStatus = data.saleStatus

    if (data.options !== null) {
      d.Options = data.options.map((pdi) => {
        const p = new ProductOptionUM()
        p.Id = pdi.optionId
        p.Name = pdi.optionName
        p.Stock = pdi.stock

        return p
      })
    }
    return d
  },
  // *2024生日禮
  GetBirthProduct_2024: async function () {
    const url = `${process.env.VUE_APP_API}/api/product/details?id=P231208000002`
    const rsp = await axios.get(url)
    if (rsp == null || rsp.data == null || rsp.data.rtnCode !== 0) {
      return null
    }

    if (rsp.data.info == null) {
      return null
    }

    const data = rsp.data.info

    const d = new ProductUM()
    d.Id = data.productId
    d.Name = data.name
    d.ShortDescription = data.shortDescription
    d.Price = data.price
    d.MSRP = data.oldPrice
    d.Specification = data.specification
    d.Description = data.description
    d.Tags = data.tags
    d.Image = data.productImage
    d.SaleStatus = data.saleStatus

    if (data.options !== null) {
      d.Options = data.options.map((pdi) => {
        const p = new ProductOptionUM()
        p.Id = pdi.optionId
        p.Name = pdi.optionName
        p.Stock = pdi.stock

        return p
      })
    }
    return d
  },
  //* 美容展商品
  GetBeautyProductList: async function () {
    const url = `${process.env.VUE_APP_API}/api/product/listedbycategory?member=S0705`
    try {
      const res = await axios.get(url)

      if (res == null || res.data == null || res.data.rtnCode !== 0) {
        throw new Error('Invalid response from API')
      }

      if (res.data.info == null) {
        throw new Error('No product info available')
      }

      const data = res.data.info.products

      if (!Array.isArray(data)) {
        throw new Error('Products data is not an array')
      }
      const products = data.map((item) => {
        const d = {
          Id: item.productId,
          Name: item.name,
          ShortDescription: item.shortDescription,
          Price: item.price,
          MSRP: item.oldPrice,
          Specification: item.specification,
          Description: item.description,
          Tags: item.tags,
          Image: item.productImage,
          SaleStatus: item.saleStatus,
          Slogan: item.slogan,
          OptionId: item.optionId
        }

        return d
      })

      return products
    } catch (error) {
      console.error('Error fetching product list:', error.message)
      return null
    }
  }

}
